import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import AboveFooterSection from '../../components/AboveFooterSection'

import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import Spacing from '../../components/partials/Spacing'

import streaming_sql from '../../resources/images/usecases/build/icons/streaming-sql.svg'
import snapshot_sql from '../../resources/images/usecases/build/icons/snapshot-sql.svg'
import application_deployment from '../../resources/images/usecases/build/icons/app-deployment.svg'
import application_monitoring from '../../resources/images/usecases/build/icons/app-monitoring.svg'
import schema_management from '../../resources/images/usecases/build/icons/schema-mgt.svg'
import acl_management from '../../resources/images/usecases/build/icons/quota-acl-mgt.svg'

import build01 from '../../resources/images/usecases/build/build01.png'
import build02 from '../../resources/images/usecases/build/build02.png'
import build03 from '../../resources/images/usecases/build/build03.png'
import build04 from '../../resources/images/usecases/build/build04.png'
import build05 from '../../resources/images/usecases/build/build05.png'
import build06 from '../../resources/images/usecases/build/build06.png'
import build07 from '../../resources/images/usecases/build/build07.png'

import ModalImage from 'react-modal-image'

import { initializeVideos } from '../../helpers/initializeVideo'
const build = () => {
  const data = useStaticQuery(graphql`
    query BuildSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Usecases_Build" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  useEffect(() => {
    try {
      initializeVideos()
    } catch (err) {
      console.log(err)
    }
  }, [])

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <StaticImage
        className="home-fixed-top"
        src="../../resources/images/homepage/hero_lens.svg"
        placeholder="blurred"
        alt="Hero Lenses"
      />
      <section className="mt-5 pt-5 pb-5">
        <div className="container-1 ipad-margin-top">
          <div className="d-flex flex-wrap mt-3">
            <div className="col-md-6 pt-0">
              <p className="title breadcrumb-text pt-4">
                <a href="/usecases/" className="h4-like">
                  USE CASES
                </a>{' '}
                <span className="h4-like ">/ Low-code Kafka streams</span>
              </p>
              <h1 className="pr-5">
                Simplify your streaming data flows with low-code Kafka streams
              </h1>
              <div className="pr-5 pt-5">
                <p className="hero-description-dark pr-5">
                  Moving to Kafka stream processing from batch is a learning
                  curve that feels like running into a brick wall. Why not use
                  skills you already have, such as SQL?
                </p>
              </div>
              <div className="roll-button">
                <a className="bg-red text-white" href="/start/">
                  <span data-hover="Get started">Get started</span>
                </a>
              </div>
            </div>
            <div className="col-md-6 pt-0 ipad-margin-top">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half"
                src="../../resources/images/usecases/build/hero-image.svg"
                placeholder="tracedSVG"
                alt="Low-code Kafka streams"
              />
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section className="bg-cyan mobile-margin-top">
        <div className="container-1 py-5">
          <div className="text-center">
            <h2 className="h1-like pb-4 w-90 mx-auto">
              Kafka stream processing doesn&apos;t have to bite.
            </h2>{' '}
            <p className="w-90 mx-auto">
              Are rekeying, exactly-once semantics and exception handling giving
              you a headache? If Kafka streaming applications can be frustrating
              to build, they are more challenging to scale, troubleshoot and
              carry to production.
            </p>
            <p className="w-90 mx-auto">
              Building & deploying Kafka stream processing applications doesn’t
              have to be so difficult.
            </p>
            <p className="w-90 mx-auto">
              And the less time you need to spend learning the fundamentals of
              Kafka, the more time you can spend solving your core business
              challenges.
            </p>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-10 mb-3">
              <div className="text-center">
                <h2 className="h1-like">
                  What are low-code Kafka streams?
                  <br /> How do they help?
                </h2>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap">
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <div className="w-100 px-5 text-center">
                  <StaticImage
                    className="w-50 mb-2"
                    placeholder="tracedSVG"
                    quality={100}
                    src="../../resources/images/usecases/build/lower-skills-required.svg"
                    alt="Lower skills required"
                  />
                </div>
                <div className="pb-4 px-2 mt-3">
                  <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                    Lower skills required
                  </h3>
                  <p className="pt-3 text-center">
                    Build & deploy streaming applications without the need to
                    learn Kafka streams
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <div className="w-100 px-5 text-center">
                  <StaticImage
                    src="../../resources/images/usecases/build/speed-up-delivery.svg"
                    quality={100}
                    placeholder="tracedSVG"
                    alt="Speed up App Delivery"
                  />
                </div>
                <div className="pb-4 px-2 mt-3">
                  <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                    Speed up App Delivery
                  </h3>
                  <p className="pt-3 text-center">
                    Self-service deployment framework of streaming apps on
                    Kubernetes
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <div className="w-100 px-5">
                  <StaticImage
                    src="../../resources/images/usecases/build/less-tech-debt.svg"
                    placeholder="tracedSVG"
                    alt="Less tech debt"
                  />
                </div>
                <div className="pb-4 px-2 mt-3">
                  <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                    Less tech debt
                  </h3>
                  <p className="pt-3 text-center">
                    Applications defined and managed as configuration & deployed
                    on your infrastructure
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section className="bg-dark mobile-margin-top">
        <div className="container-1 py-5">
          <div className="text-center">
            <div className="w-15 mb-4 mobile-w-50 mx-auto">
              <StaticImage
                className="img-fluid"
                src="../../resources/images/usecases/build/avanza-logo.svg"
                placeholder="tracedSVG"
                alt="Avanza Logo"
              />
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <div className="col-sm-8 col-10">
              <p className="text-white f-20 lh-35 text-center">
                “Lenses not only helps developers to understand which data is
                there and how the data is represented, but provides a feedback
                mechanism on the schema itself. It’s a big part of our low-code
                app development process.”
              </p>
            </div>
            <div className="col-sm-8 col-11">
              <div className="d-flex flex-wrap align-items-center justify-content-center">
                <StaticImage
                  src="../../resources/images/case_studies/avanza/anders.png"
                  quality={100}
                  alt="Anders Eriksson"
                  placeholder="tracedSVG"
                />
                <p className="f-18 fw-900 text-center text-white ml-3 mobile-text-center mt-3">
                  {' '}
                  Anders Eriksson, Data Engineer - Avanza
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1 mt-5 pb-5 mobile-margin-top mobile-padding-reset">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-5 col-12">
              <div className="mobile-padding-reset ">
                <h2 className="paragraph-title">
                  Can we develop on Kafka without understanding the nuances of
                  stream processing?
                </h2>
                <p>
                  Filter, aggregate, transform and reshape data with streaming
                  SQL, deploying over your existing Kubernetes or Connect
                  infrastructure.
                </p>
              </div>
            </div>
            <div className="offset-md-1 offset-0"></div>
            <div className="col-md-6 col-12">
              <ModalImage
                className="w-100 shadow-lg"
                small={build01}
                large={build01}
                alt="Lenses SQL - data visibility"
              />
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 py-5 mobile-margin-top mobile-padding-reset">
          <div className="d-flex flex-wrap flex-column-reverse flex-sm-row pt-4 align-items-center mobile-padding-reset">
            <div className="col-md-6  col-12">
              <ModalImage
                className="w-100 shadow-lg"
                small={build02}
                large={build02}
                alt="Lenses SQL Processor"
              />
            </div>
            <div className="offset-md-1 offset-0"></div>
            <div className="col-md-5 col-12 ">
              <div>
                <h2 className="paragraph-title mobile-margin-top">
                  How can we be sure that our Kafka Connect connectors are
                  running correctly?
                </h2>
                <p>
                  Deploy, manage & monitor your Kafka connect connectors all
                  from a UI and with error handling and alerting.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 py-5 mobile-margin-top mobile-padding-reset ">
          <div className="d-flex flex-wrap pt-4 align-items-center mobile-padding-reset">
            <div className="col-md-5 col-12 ">
              <div>
                <h2 className="paragraph-title mobile-margin-top">
                  Our streaming application landscape is a wild west. How can we
                  know who has deployed what?
                </h2>
                <p>
                  Document and tag applications across different product teams,
                  frameworks and deployment pipelines.
                </p>
              </div>
            </div>
            <div className="offset-md-1"></div>
            <div className="col-md-6  col-12 ">
              <ModalImage
                className="w-100 shadow-lg"
                small={build03}
                large={build03}
                alt="Lenses - Kafka Connect"
              />
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 py-5 mobile-margin-top mobile-padding-reset">
          <div className="d-flex flex-wrap flex-column-reverse flex-sm-row pt-4 align-items-center mobile-padding-reset">
            <div className="col-md-6  col-12">
              <ModalImage
                className="w-100 shadow-lg"
                small={build04}
                large={build04}
                alt="Data Landscape Git"
              />
            </div>
            <div className="offset-md-1 offset-0"></div>
            <div className="col-md-5 col-12  ">
              <h2 className="paragraph-title mobile-margin-top">
                Can I see how my Kafka streaming applications are connected?
              </h2>
              <p>
                The real-time application topology provides a data-centric,
                google-maps style view of the dependencies between different
                apps and flows.
              </p>
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 py-5 mobile-margin-top mobile-padding-reset">
          <div className="d-flex flex-wrap pt-4 mobile-padding-reset align-items-center">
            <div className="col-md-5 col-12 ">
              <div className="mobile-padding-reset ">
                <h2 className="paragraph-title mobile-margin-top">
                  How can I explore or inject data in a Kafka topic to debug my
                  application?
                </h2>
                <p>
                  Use a secure UI or API to produce events into a Kafka to test
                  your event-driven application. Works across all
                  serializations.
                </p>
              </div>
            </div>
            <div className="offset-md-1 offset-0"></div>
            <div className="col-md-6  col-12">
              <ModalImage
                className="w-100 shadow-lg"
                small={build05}
                large={build05}
                alt="Add new rule - Lenses Alerts"
              />
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 py-5 mobile-margin-top mobile-padding-reset">
          <div className="d-flex flex-wrap pt-4 flex-column-reverse flex-sm-row align-items-center mobile-padding-reset">
            <div className="col-md-6  col-12">
              <ModalImage
                className="w-100 shadow-lg"
                small={build06}
                large={build06}
                alt="Create, evolve and verify schemas"
              />
            </div>
            <div className="offset-md-1 offset-0"></div>
            <div className="col-md-5 col-12  ">
              <h2 className="paragraph-title mobile-margin-top">
                How can we create, evolve and verify schemas?
              </h2>
              <p>
                Manage and evolve schemas held in your 3rd party schema registry
                such as Confluent or Cloudera, protected by RBAC.
              </p>
            </div>
          </div>
        </div>

        <div className="container-1 mt-5 py-5 mobile-margin-top mobile-padding-reset">
          <div className="d-flex flex-wrap pt-4 mobile-padding-reset align-items-center">
            <div className="col-md-5 col-12 ">
              <div className="mobile-padding-reset ">
                <h2 className="paragraph-title mobile-margin-top">
                  We need to integrate and convert data from legacy sources in
                  formats such as JSON to AVRO
                </h2>
                <p>
                  Deploy data transformation workloads defined as SQL to convert
                  data such as JSON, XML or CSV to AVRO or other formats
                </p>
              </div>
            </div>
            <div className="offset-md-1 offset-0"></div>
            <div className="col-md-6  col-12">
              <ModalImage
                className="w-100 shadow-lg"
                small={build07}
                large={build07}
                alt="JSON to AVRO"
              />
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section className="footer-color py-5 mobile-margin-top">
        <div className="text-center">
          <div className="container-1">
            <div className="col">
              <h2 className="h1-like">Low-code Kafka streams architecture</h2>
            </div>
          </div>
          <StaticImage
            className="w-75 mobile-w-100"
            src="../../resources/images/usecases/build/low-code-architecture.svg"
            placeholder="tracedSVG"
            alt="Low-code Kafka streams architecture"
          />
        </div>
      </section>

      <Spacing />

      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-lg-6 col-md-8 col-12">
              <h2 className="h1-like cera-pro text-center">
                What are the components of low-code Kafka streams?
              </h2>
            </div>
            <div className="col-sm-11 col-12 mobile-margin-reset">
              <div className="d-flex flex-wrap">
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={streaming_sql}
                    alt="Streaming SQL"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    Streaming SQL
                  </p>
                </div>
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={snapshot_sql}
                    alt="Snapshot SQL"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    Snapshot SQL
                  </p>
                </div>
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={application_deployment}
                    alt="Application Deployment"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    Application <br /> Deployment
                  </p>
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={application_monitoring}
                    alt="Application Monitoring"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    Application <br /> Monitoring
                  </p>
                </div>

                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={schema_management}
                    alt="Schema Management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    Schema <br /> Management
                  </p>
                </div>
                <div className="col-sm mt-5 col-12">
                  <img
                    className="usecase-feature"
                    src={acl_management}
                    alt="ACL Management"
                  />
                  <p className="mt-3 text-uppercase cera-pro text-center fw-900 f-20">
                    ACL <br /> Management
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section className="bg-usecase-viseca py-5 mobile-margin-top">
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-7 col-12">
              <p className="f-16 text-center cera-pro text-uppercase text-white">
                {' '}
                Case Study
              </p>
              <div className="text-center w-40 mx-auto py-4">
                <StaticImage
                  className="img-fluid w-50"
                  src="../../resources/images/usecases/build/viseca-card-services.svg"
                  placeholder="tracedSVG"
                  alt="Playtika"
                />
              </div>
              <h2 className="fw-900 text-center text-white h1-like">
                This Swiss bank takes minutes to build and deploy Kafka stream
                processing applications
              </h2>
            </div>

            <div className="col-sm-10 col-12">
              <p className="f-20 text-white text-center mb-0">
                This highly regulated financial services provider used Lenses to
                help teams deploy on Kafka without constraints, speeding up
                time-to-market of streaming apps by 10x and sending 600k
                targeted communications to end-customers.
              </p>

              <div className="mt-4 text-center">
                <a className="primary-text" href="/customers/viseca/">
                  Read more <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-5 col-12 pb-0 mobile-padding-left tablet-padding-left">
              <p className="h4-like mb-1">Video</p>
              <h2>
                Building a Cloud Data Platform 0 to 100 in Under 60 Minutes
              </h2>
              <ul className="red-checked-list pl-0">
                <li>
                  <span>How to build a cloud data platform from scratch</span>
                </li>
                <li>
                  <span>How to build and deploy a Kafka data stream</span>
                </li>
                <li>
                  <span>
                    How to add observability & data governance to the platform
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-md-1 col-12"></div>
            <div className="col-md-6 col-12 mobile-margin-top">
              <div className="cloud-aws-msk-video">
                <iframe
                  src=""
                  title="Building a Cloud Data Platform - 0 to 100 in Under 60 Minutes"
                  data-src="https://www.youtube.com/embed/T2XiMnz_Y6c"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                  frameBorder="0"
                  allow="accelerometer; fullscreen"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap">
            <div className="col-md col-sm-6 col-12 mt-3">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100 rounded-top-1"
                  src="../../resources/images/usecases/data-observability/blog/data-capture.png"
                  placeholder="tracedSVG"
                  alt="Drive your streaming data with SQL"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">Product / SQL</p>
                  <div>
                    <p className="cera-pro">
                      Drive your streaming data with SQL
                    </p>
                  </div>
                  <a
                    className="primary-text home-link-box"
                    href="/product/sql/"
                  >
                    More about SQL <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-3">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100 rounded-top-1"
                  src="../../resources/images/usecases/build/blog/processin-data-sql.jpeg"
                  placeholder="tracedSVG"
                  alt="Streaming SQL Blog"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">Blog</p>
                  <div>
                    <p className="cera-pro d-inline">
                      Why our Streaming SQL opens up your data platform
                    </p>
                  </div>
                  <a
                    className="primary-text home-link-box"
                    href="/blog/2020/07/Why-new-Streaming-SQL-opens-up-data-platform/"
                  >
                    Read blog <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-3">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100 rounded-top-1"
                  src="../../resources/images/usecases/build/blog/cheatsheet.png"
                  placeholder="tracedSVG"
                  alt="Streaming SQL cheatsheet for Apache Kafka"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">Cheat Sheet</p>
                  <div>
                    <p className="cera-pro">
                      Streaming SQL cheatsheet for Apache Kafka
                    </p>
                  </div>
                  <a
                    className="primary-text home-link-box"
                    href="/resources/streaming-sql-cheat-sheet-for-apache-kafka/"
                  >
                    Download now <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 mobile-margin-top mt-4">
          <div className="d-flex flex-wrap">
            <div className="col-md col-sm-6 col-12 mt-3">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100 rounded-top-1"
                  src="../../resources/images/usecases/build/blog/video.png"
                  placeholder="tracedSVG"
                  alt="GitOps for streaming data on Kafka and Kubernetes"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">Resources</p>
                  <div>
                    <p className="cera-pro">
                      GitOps for streaming data on Kafka and Kubernetes
                    </p>
                  </div>
                  <a
                    className="primary-text home-link-box"
                    href="/videos/kafka-gitops-devops-webinar/"
                  >
                    Watch video <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-3">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100 rounded-top-1"
                  src="../../resources/images/usecases/build/blog/lateral-joins-arrays-for-kafka.png"
                  placeholder="tracedSVG"
                  alt="Exploding arrays in Kafka with lateral joins"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">Blog</p>
                  <div>
                    <p className="cera-pro d-inline">
                      Exploding arrays in Kafka with lateral joins
                    </p>
                  </div>
                  <a
                    className="primary-text home-link-box"
                    href="/blog/2020/12/exploding-arrays-in-kafka-with-lateral-joins/"
                  >
                    Read blog <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-3">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100 rounded-top-1"
                  src="../../resources/images/usecases/build/blog/spatial-analysis.png"
                  placeholder="tracedSVG"
                  alt="Geospatial data processing"
                />
                <div className="py-4 px-3">
                  <p className="h4-like mb-1">Blog</p>
                  <div>
                    <p className="cera-pro">
                      Geospatial data processing with streaming SQL for Apache
                      Kafka
                    </p>
                  </div>
                  <a
                    className="primary-text home-link-box"
                    href="/blog/2020/12/geo-spatial-sql-data-processing-for-apache-kafka/"
                  >
                    Read blog <i className="red-pointer-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'Get your DataOps workspace for Apache Kafka'}
        ctaText={"Let's go"}
        ctaLink="/start/"
      />
    </Layout>
  )
}
export default build
